<template>
  <div>
    <b-card>
      <b-button class="m-1" variant="dark" v-b-toggle.collapse>SKU 패턴이 등록된 샵 리스트</b-button>

      <b-collapse id="collapse">
        <div>
          <b-button class="ml-1 mb-1"
                    variant="outline-dark"
                    v-for="s in items.stat" :v-if="s.brand_no"
                    size="sm"
                    @click="() => {form.brand = brandMap[s.brand_no]; form.search = ''; items.list = []; list()}">
            {{s.brand_no}}. {{s.brand_nm}}: {{s.cnt}}
          </b-button>
        </div>
      </b-collapse>
    </b-card>
    <b-tabs v-model="tabIndex">
      <b-tab title="통합 SKU 패턴 관리">
        <div>
          <b-row>
            <b-col cols="12">
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-button variant="primary" @click="list()" :disabled="busy.list">
                    <i class="fa fa-search"></i> 검색
                    <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input type="text" placeholder="검색할 브랜드 이름, 브랜드 번호를 입력해주세요." v-model="form.search" @keypress.enter.prevent.stop="list()" v-focus></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
          <div>
            <b-button class="ml-1 mb-1" variant="outline-success" v-for="s in items.stat.slice(0, 10)" :v-if="s.brand_no" size="sm" @click="() => {form.brand = brandMap[s.brand_no]; form.search = '';  items.list = []; list()}">
              {{s.brand_no}}. {{s.brand_nm}}: {{s.cnt}}
            </b-button>
          </div>
          <v-select v-model="form.brand" :options="brand" placeholder="브랜드를 선택해주세요" class="pb-2"></v-select>
          <hr/>
          <div class="clearfix mb-1">
            <b-button class="mr-2 bg-blue" size="sm" variant="primary" @click="selectAllShop">전체 선택/해제</b-button>
            <b-button class="mr-2" size="sm" variant="success" @click="add">추가</b-button>
            <b-button class="mr-2" size="sm" variant="danger" @click="remove">삭제</b-button>
            <b-button class="mr-2" size="sm" variant="primary" @click="save">저장</b-button>
            <b-button class="mr-2" size="sm" @click="syncSku">싱크</b-button>
            <div class="pull-right">
              <xlsx :types="['xlsx']" :preFunc="preDown" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="Skus"></xlsx>
            </div>
          </div>
          <hot-table ref="hotTableSku" :settings="hotSettingsSku"></hot-table>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style>
td.custom-cell-pink {
  background-color: #FFD8D8;
}
td.custom-cell-green {
  background-color: #CFEAC5;
}
td.custom-cell-orange {
  background-color: #F0EFC0;
}
td.custom-cell-gray {
  color: #fff;
  background-color: #DADADA;
}
.custom-table thead th:nth-child(even),
.custom-table tbody tr:nth-child(odd) th {
  background-color: #d7f1e1;
}
</style>
<script>
import xlsx from '@/views/modules/Xlsx.vue'
import { HotTable } from '@handsontable/vue';
import {callApi, getMeta} from "@/shared/api";

export default {
  name: "SkuPattern",
  components: {xlsx, HotTable},
  data() {
    return {
      form: {
        brand: '',
        search: ''
      },
      tabIndex: 0,
      brand: [],
      brandMap: {},
      brandNmMap: {},
      lastBody: {list: {}},
      items: {list: [], stat: []},
      busy: {list: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      perPage: 20,
      xlsx: {
        selected: [],
        keys: [],
        labels: [],
      },
      fields: {
        sku: [
          {data: 'selected', type: 'checkbox'},
          {data: 'brand_nm',  name: '브랜드 이름', width: 200, readOnly: true},
          {data: 'brandNo',  name: '브랜드 ID', width: 80, readOnly: true},
          {data: 'priority', name: '우선순위', width: 100, type: 'numeric'},
          {data: 'regexPattern',  name: '패턴', width: 600},
          {data: 'outputFormat', name: 'format', width: 100},
          {data: 'patternType',  name: '패턴 타입', width: 80, type: 'autocomplete', source: ['Full', 'Short'], filter: false},
          {data: 'isActive',  name: '사용중', width: 60, type: 'autocomplete', source: [true, false], filter: false},
          {data: 'sample',  name: '샘플 SKU', width: 200},
          {data: 'memo',  name: '메모'},
          {data: 'mdt',  name: '수정시각', readOnly: true},
          {data: 'createdAt', name: '생성시각', readOnly: true},
        ]
      },
      hotSettingsSku: {
        data: [],
        colHeaders: (index) => {
          if (index === 0) {
            return `${this.items.list.filter(e => e.selected).length}/${this.items.list.length}`;
          }
          return this.fields.sku.map(e => e.name)[index];
        },
        columns:  [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        height: 550,
        hotTableShopsLastRange: {},
        fixedColumnsLeft: 3,
        licenseKey: 'non-commercial-and-evaluation',
        className: 'custom-table',
        cells: (row, col, prop) => {
          let className = {
            regexPattern: 'custom-cell-pink',
            outputFormat: 'custom-cell-pink',
            brandNo: 'custom-cell-gray',
            patternType:  this.items.list[row] && this.items.list[row].patternType === 'Full' ? 'custom-cell-green' :  'custom-cell-orange'
          }[prop] || '';
          return className ? {className} : {};
        },
        afterChange: (changes) => {
          if (changes) {
            changes.filter(chg => chg[2]).forEach(chg => {
              if (chg.includes('priority') && chg[2] !== chg[3]) {
                if (this.items.list.filter(item => item.priority === chg[3]).length === 2) {
                  if (chg[3] > chg[2]) {
                    this.items.list.filter(item => item.priority <= chg[3] && item.priority >= chg[2]).forEach(item=> item.priority -= 1);
                  } else if (chg[3] < chg[2]) {
                    this.items.list.filter(item => item.priority >= chg[3] && item.priority <= chg[2]).forEach(item=> item.priority += 1);
                  }
                }
                this.items.list[chg[0]].priority = chg[3];
              }
            });

            changes.filter(chg => !chg[2]).forEach(chg => {
              if (chg.includes('priority') && chg[2] !== chg[3]) {
                const copyList = this.$utils.clone(this.items.list);
                copyList.sort((a, b) => b.priority - a.priority);

                if (chg[2] === null) {
                  this.items.list[chg[0]].priority = copyList[0].priority + 1;
                }
              }
            });
            this.$refs.hotTableSku.hotInstance.loadData(this.items.list);
          }
        },
      },
    };
  },
  async created() {
    this.hotSettingsSku.columns = this.fields.sku;
    let meta = await getMeta('brand');
    if (!meta) return;

    // 221219 미사용 브랜드 제외
    this.brand = meta.brand.filter(e => !e.disabled).sort((a, b) => a.brand_no - b.brand_no);
    this.brand.forEach(b => {
      b.value = b.brand_nm;
      b.label = `${b.brand_no}. ${b.brand_nm}(${b.brand_nm_kr})`;
      this.brandMap[b.brand_no] = b;
      this.brandNmMap[b.brand_nm] =b;
    });
    await this.stat();
    this.list();
  },
  methods: {
    selectAllShop() {
      let selectAll = !this.items.list.every(e => e.selected);
      this.items.list.forEach(e => e.selected = selectAll);
      this.$refs.hotTableSku.hotInstance.loadData(this.items.list);
    },
    async stat() {
      const res = await callApi(this.CATALOG_API_URL + '/sku-extract/patterns/count');
      const stat = res.value;
      this.brand.forEach(brand => {
        if (stat[brand.brand_no]) {
          brand.label = `${brand.brand_no}. ${brand.brand_nm}(${brand.brand_nm_kr}) / 패턴 등록됨: ${stat[brand.brand_no]}`;
        } else {
          brand.label = `${brand.brand_no}. ${brand.brand_nm}(${brand.brand_nm_kr}) / 패턴 없음`;
        }
      });
      this.items.stat = this.brand.filter(b => stat[b.brand_no]).map(b=> ({
        brand_no: b.brand_no,
        cnt: stat[b.brand_no],
        brand_nm: b.brand_nm
      })).sort((a, b) => b.cnt - a.cnt);

      this.$forceUpdate();
    },
    async list() {
      this.busy.list = true;
      if (!this.form.brand && !this.form.search) {
        this.items.list = [{regexPattern: '브랜드를 선택해주세요'}];
      } else {
        let {brand, search} = this.form;
        if (search) search = search.trim();
        let target_brand = '';

        if (search) {
          const findBrand = this.brand.filter(b => b.brand_nm === search || b.brand_nm_kr === search || b.brand_no === +search)
          if (findBrand[0] && findBrand[0].brand_no) {
            this.form.brand = this.brandMap[findBrand[0].brand_no]

            target_brand = findBrand[0].brand_no;
          }
        } else if (brand && brand.brand_no) {
          target_brand = brand.brand_no;
        }

        let res;
        if (target_brand) {
          res = await callApi(this.CATALOG_API_URL + `/sku-extract/patterns?brandNo=${target_brand}`);
        } else {
          alert('브랜드를 선택해서 조회해주세요.');
          return;
          // res = await callCatalogApi('/sku-extract/patterns');
        }

        if (res && res.ok) {
          const data = res.value;

          data.forEach(item => item.brand_nm = (this.brandMap[item.brandNo] || {}).brand_nm);
          this.items.list = data;

          if (!this.form.brand && data.length) {
            this.form.brand = this.brandMap[data[0].brand_no];
          }
        } else {
          alert('SKU pattern을 불러오는데 문제가 생겼습니다.')
        }
      }
      this.$refs.hotTableSku.hotInstance.loadData(this.items.list);
      this.busy.list = false;
    },
    remove() {
      const delSkuIdMap = {};
      this.items.list.filter(s=> s.selected).forEach(s=> delSkuIdMap[s.id] = true);
      this.items.list = this.items.list.filter(s=> !delSkuIdMap[s.id]);
      this.$refs.hotTableSku.hotInstance.loadData(this.items.list);
    },
    add() {
      if (!this.form.brand && !this.brandNmMap[this.form.search] && !this.brandMap[this.form.search]) {
        return;
      }

      const brand = this.form.brand || this.brandNmMap[this.form.search] || this.brandMap[this.form.search];
      const obj = {};
      const copyList = this.item = this.$utils.clone(this.items.list);

      copyList.sort((a, b) => b.priority - a.priority);
      this.fields.sku.forEach(s=> {
        if (s.data === 'brandNo') obj[s.data] = brand.brand_no
        else if (s.data === 'brand_nm') obj[s.data] = brand.brand_nm;
        else obj[s.data] = null;
      });
      obj.priority = +((copyList[0] || {}).priority || 0) + 1;
      this.items.list.push(obj);
      this.$refs.hotTableSku.hotInstance.loadData(this.items.list);
    },
    async save() {
      const brand = this.form.brand || this.brandNmMap[this.form.search] || this.brandMap[this.form.search];

      if (!brand) {
        return alert('저장할 브랜드가 없습니다.');
      }

      this.items.list.filter(item=> !item.brandNo).forEach(item => item.brandNo = brand.brand_no);
      const patterns = this.items.list.map(item => {
        delete item.id;
        return item;
      })
      const body = {patterns, brandNo: brand.brand_no};
      const res = await callApi(this.CATALOG_API_URL + `/sku-extract/patterns`, {method: 'post', body});
      if (res && res.ok) {
        return alert(`${brand.brand_no}. ${brand.brand_nm} 패턴을 저장했습니다.`);
      } else if (res && res.err) {
        return alert(res.err);
      }
    },
    async syncSku() {
      if (!confirm('싱크하시겠습니까? 브랜드를 선택 안했을 경우 전체 SKU가 싱크 됩니다.')) return;
      let all = false;
      if (!this.form.brand && !this.brandNmMap[this.form.search] && !this.brandMap[this.form.search]) {
        all = true;
      }
      const brand = this.form.brand || this.brandNmMap[this.form.search] || this.brandMap[this.form.search] || {};
      const body = {};
      const query = all ? '' : `?brandNo=${brand.brand_no}`
      const res = await callApi(this.CATALOG_API_URL + `/sku-extract` + query, {method: 'post', body});

      if (res && res.ok) {
        if (!all) {
          alert(`${brand.brand_no}. ${brand.brand_nm} 싱크를 시작합니다.`);
        } else {
          alert(`${res.value.length}개의 브랜드 싱크를 시작합니다.`);
        }
      }
    },
    async preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);
      if (!this.xlsx.selected.length) {
        if (!confirm('전체 SKU 패턴을 다운 받으시겠습니까?')) return;


        const res = await callApi(this.CATALOG_API_URL + '/sku-extract/patterns');
        if (res && res.ok) {
          res.value.forEach(item => item.brand_nm = (this.brandMap[item.brandNo] || {}).brand_nm);
          this.xlsx.selected = res.value;
        } else {
          return alert('엑셀 다운로드에 실패하였습니다.');
        }
      }

      this.xlsx.keys = this.fields.sku.filter(f=> f.data !== 'selected').map(f=> f.data);
      this.xlsx.labels = this.fields.sku.filter(f=> f.data !== 'selected').map(f=> f.name);

      return true;
    },
  }
}
</script>
